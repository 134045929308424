import axios from "axios";
import vueApp from "@/main";

export const axiosApi = axios.create({
  baseURL: process.env.VUE_APP_SUBS_ARTIST_BASE_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
});

axiosApi.interceptors.request.use(async (config) => {
  const token = await vueApp.$auth.getTokenSilently();
  config.headers['Authorization'] = `Bearer ${token}`;
  return config;
}, (error) => {
  return Promise.reject(error);
});

axiosApi.interceptors.response.use(response => {
  return response.data;
}, error => {
  return Promise.reject(error);
});
